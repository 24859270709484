import React,{useEffect} from 'react';
import { useSelector,useDispatch } from "react-redux";
import jwt from 'jsonwebtoken';
import { useLocation, useHistory } from "react-router-dom";
import {setLogout}  from "./store/action/userAction";

const Auth = ({ children }) => {
    const userModel = useSelector(state => state.user);
    console.log(userModel);

    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        checkLogin();
      }, [userModel.isLogin,location.pathname]);

    console.log(children)
    function checkLogin() {
        // localStorage.setItem("Token", 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjg3MTUzODMxLCJpYXQiOjE2ODcxNTE4MzEsImp0aSI6ImZlY2M5Njg3OTY4YzRhYjFhMjVmMmM5Yzg3NmNhNWQ5IiwidXNlcl9pZCI6MjN9.F4iAgWkROy8LLQcbJimASn60IKM0wkXI3rMEfoNUr9U');
        const token = localStorage.getItem('Token');
        const decodedToken = jwt.decode(token);
        const currentTime = Date.now() / 1000;
        if(userModel.isLogin && location.pathname!=="/login" && decodedToken.exp < currentTime) {
            dispatch(setLogout());
        }else if (!userModel.isLogin && location.pathname!=="/LeadVerification" && location.pathname!=="/owner_signup" && location.pathname!=="/PrivacyPolicy" && location.pathname!=="/TermsConditions") {
            if (!userModel.isLogin && location.pathname!=="/login") {
                history.push('/login')
            }
        
        
        }else if(!userModel.isLogin && (location.pathname==="/RegisterPropertyOwner" || location.pathname==="/owner_signup")) {
            console.log("user not logged in & path is register");
            
        }else if(location.pathname==="/LeadVerification") {
            console.log("path is lead");
        }else if(location.pathname==="/PrivacyPolicy") {
            console.log("path is privacy");
        }else if(location.pathname==="/TermsConditions") {
            console.log("path is terms and conditions");
        }
        else {
            if (location.pathname=="/login") {
                history.push('/property')   
            }
        }
        return (
            <div key="AuthController">
                {children}
            </div>
        );
    }
    console.log("I am auth");
    // return (
    //     <div key="AuthController">
    //         {children}
    //     </div>
    // );
    return checkLogin();
}

export default Auth;
