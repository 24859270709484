import { combineReducers } from "redux";
import { userReducer } from "./reducer/userReducer";
import { changeState } from "./reducer/changeState";

const reducers = combineReducers({
    user: userReducer,
    state: changeState,
});

export default reducers;
