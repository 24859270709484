import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import './scss/style.scss'
import Auth from './Auth'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./pages/login/Login.js'))
const Register = React.lazy(() => import('./pages/property_owner/owner_registration.js'))
const Page404 = React.lazy(() => import('./pages/page404/Page404.js'))
const ContactUs = React.lazy(() => import('./pages/footer_links/contact_us'))
const RefundPolicy = React.lazy(() => import('./pages/footer_links/refund_policy'))
const TermsConditions = React.lazy(() => import('./pages/footer_links/terms_conditions'))
const PrivacyPolicy = React.lazy(() => import('./pages/footer_links/privacy_policy'))
const Page500 = React.lazy(() => import('./pages/page500/Page500.js'))
const LeadVerification = React.lazy(() => import('./pages/leads_verification/leads_verification'))


class App extends Component {
  render() {
    return (
      <HashRouter>
        <Auth>
        <React.Suspense fallback={loading}>
            
          <Switch>
        <Route exact path="/LeadVerification" name="Lead Verification" render={(props) => <LeadVerification {...props} />} />
          <Route
              exact
              path="/owner_signup"
              name="Property Owner Registration"
              render={(props) => <Register {...props} />}
            />
            <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
            
            <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
            <Route exact path="/contactUs" name="Contact Us" render={(props) => <ContactUs {...props} />} />
            <Route exact path="/RefundPolicy" name="Refund Policy" render={(props) => <RefundPolicy {...props} />} />
            <Route exact path="/TermsConditions" name="Terms Conditions" render={(props) => <TermsConditions {...props} />} />
            <Route exact path="/PrivacyPolicy" name="Privacy Policy" render={(props) => <PrivacyPolicy {...props} />} />
            <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
            <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />
          </Switch>
        </React.Suspense>
        </Auth>
      </HashRouter>
    )
  }
}

export default App